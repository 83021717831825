header {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 30px;
    @media (max-width: 768px) {
      display: block;
    }
    @media (max-width: 576px) {
      display: flex;
      margin: 0 10px;
    }
  }

  .logo_wrapper {
    width: 200px;
    margin: 0;
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      width: 150px;
    }
    a {
      display: inline-flex;
      align-items: center;
    }

    .logo {
      width: 100%;
    }
  }

  #pc_menu {
    display: flex;
    align-items: center;
    margin: 0 80px 0 0;

    @media (max-width: 768px) {
      margin: 0 80px 20px 0;
    }

    @media (max-width: 576px) {
      margin: 0 80px 0 0;
      display: none;
    }

    .links,
    .sns {
      display: flex;
      align-items: center;
      margin: 0 40px 0 0;

      li {
        list-style: none;
        margin-left: 40px;
        font-size: 15px;

        a {
          text-decoration: none;
          color: #999;
          font-weight: 500;
          transition: 0.3s;
          &:hover {
            color: #E37428;
          }
        }
      }

      .line,
      .instagram {
        margin-left: 40px;
        a {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
        }
      }
    }
  }

  #sp_menu {
    display: none;
    @media (max-width: 576px) {
      display: block;
    }
  }
}
