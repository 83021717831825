#post_page {
  padding: 50px 0;
  border: 1px solid rgba(0,0,0,0.1);
  .post_page_wrapper {
    width: 80%;
    margin: 0 auto;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .post_container {
    border: 1px solid #eee;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    margin: 0 50px;
    padding: 50px 100px;
    @media (max-width: 576px) {
      margin: 0 20px;
      padding: 10px 30px;
    }
    p {
      margin: 0;
      margin-bottom: 10px;
    }
    .title {
    }
    .date {
      font-size: 14px;
      text-align: right;
    }
    figure {
      margin: 0;
    }
    img {
      width: 50%;
      object-fit: contain;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
}
