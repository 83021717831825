#reserve_button_container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  @media (max-width: 576px) {
    right: 10px;
    bottom: 10px;
  }
  .reserve_btn {
    background: #fff;
    color: #000;
    font-weight: bold;
    border-radius: 50px;
    padding: 10px 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 40px;
      margin-right: 10px;
    }
    .letter {
      margin-right: 20px;
      @media (max-width: 576px) {
        margin-right: 10px;
      }
    }
  }
}
