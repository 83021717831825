#sp_menu {
  .menu-trigger {
    display: block;
    &.active {
      display: none;
    }
    span {
      display: block;
      margin-bottom: 3px;
      width: 20px;
      height: 2px;
      background: #000;
    }
  }

  .sp_menu_overlap {
    display: none;

    &.active {
      display: flex;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background: #fff;
      z-index: 100;
      margin: 0;
      padding: 80px 0 0;
      box-sizing: border-box;
      flex-direction: column;
    }

    .sp_menu_link {
      padding: 20px 0 20px 40px;
      text-decoration: none;
      font-size: 20px;
      color: #999;
      border-top: 1px solid #999;
      &:last-of-type {
        border-bottom: 1px solid #999;
      }
      &:hover {
        color: #E37428;
      }
    }

    .menu-close-trigger {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      img {
        width: 20px;
        fill: #fff;
      }
    }
  }

  .sp_menu_footer {
    margin-top: 30px;
    .logo_wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 300px;
      }
    }

    .sns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 40px 0 0;

      li {
        list-style: none;
        margin-left: 40px;
        font-size: 15px;
        &:first-child {
          margin-left: 0;
        }
      }

      .line,
      .instagram {
        margin-left: 40px;
        a {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
        }
      }
    }

    .address {
      text-align: center;
      margin-top: 40px;
    }

    .open_hours {
      text-align: center;
    }
  }
  .reserve_btn_container {
    text-align: center;
    margin-top: 40px;
    .reserve_btn {
      border: 1px solid #E37428;
      display: inline-block;
      padding: 15px 80px;
      color: #E37428;
      border-radius: 3px;
      cursor: pointer;
      transition: 0.5s;
      font-weight: bold;
      text-decoration: none;
      &:hover {
        background: #E37428;
        color: #fff;
      }
    }
  }
}
