#top_page {
  .title_container {
    text-align: center;
    .title {
      font-size: 40px;
      position: relative;
      display: inline-block;
      margin: 0 auto;
      margin-bottom: 1em;
      @media (max-width: 576px) {
        font-size: 25px;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -10px;
        display: inline-block;
        width: 60px;
        height: 5px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: #E37428;
        border-radius: 2px;
        @media (max-width: 576px) {
          width: 45px;
        }
      }
    }
  }

  #top_section {
    background-size: cover;
    background-position: center;
    height: 800px;
    position: relative;
    @media (max-width: 576px) {
      height: 270px;
    }

    .top_content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      margin: 0 auto;
      text-align: center;
      .title {
        font-size: 50px;
        color: #fff;
        text-shadow: 0 0 10px #000;
        margin: 0;
        box-sizing: border-box;
        @media (max-width: 576px) {
          font-size: 30px;
        }
      }
      .title_br {
        display: none;
        @media (max-width: 576px) {
          display: block;
        }
      }
      .description {
        font-size: 25px;
        color: #fff;
        text-shadow: 0 0 20px #000;
        margin: 0;
        box-sizing: border-box;
        font-weight: bold;
        @media (max-width: 576px) {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }

  #about_section {
    margin-top: 40px;
    .about_section_wrapper {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding-bottom: 40px;
      width: 80%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }
    }

    .about_container {
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .left {
      width: 55%;
      @media (max-width: 768px) {
        width: 100%;
        display: none;
      }
      img {
        width: 100%;
      }
    }

    .right {
      width: 45%;
      margin-left: 50px;
      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }
      .description {

        @media (max-width: 768px) {
          .sub br {
            display: none;
          }
        }
      }
    }

    .reserve_btn_container {
      text-align: center;
      margin-top: 30px;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
      .reserve_btn {
        border: 1px solid #E37428;
        display: inline-block;
        padding: 15px 80px;
        color: #E37428;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.5s;
        font-weight: bold;
        text-decoration: none;
        &:hover {
          background: #E37428;
          color: #fff;
        }
      }
    }
  }

  #drink_section {
    margin-top: 40px;
    .drink_section_wrapper {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding-bottom: 40px;
      width: 80%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }
    }

    .drinks_container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .drink {
        width: calc(25% - 8px);
        text-decoration: none;
        margin-bottom: 20px;
        display: inline-block;
        @media (max-width: 768px) {
          width: calc(50% - 8px);
          margin-bottom: 20px;
        }
        @media (max-width: 576px) {
          width: 100%;
        }

        &.sp-none {
          @media (max-width: 576px) {
            display: none;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .drink_image_container {
          width: 100%;
          transition: 0.5s;
          img {
            width: 100%;
            vertical-align: bottom;
            transition: 0.5s;
          }
        }

        .description {
          color: #444242;
          margin: 5px 0 0;
          font-size: 14px;
          transition: 0.5s;
        }
        .tag {
          color: #01376A;
          font-size: 12px;
        }

        &:hover {
          .drink_image_container {
            background: #000;
            img {
              opacity: 0.8;
            }
          }
          .description {
            color: #E37428;
          }
        }
      }
    }
    .more_drinks_wrapper {
      text-align: center;
      margin: 30px 0 30px;
    }
    .more_drinks {
      border: 1px solid #444242;
      color: #444242;
      font-weight: bold;
      padding: 15px 30px;
      text-decoration: none;
      font-size: 14px;
      transition: 0.5s;
      &:hover {
        border: 1px solid #E37428;
        color: #E37428;
      }
    }

    .drink_video_title {
      text-align: center;
    }

    .drink_video {
      display: flex;
      justify-content: center;
    }
  }

  #information_section {
    margin-top: 40px;
    .information_section_wrapper {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding-bottom: 40px;
      width: 80%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }
    }

    .blog_lists {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .blog_container {
      width: calc(25% - 8px);
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      margin-right: 8px;
      margin-bottom: 10px;
      transition: 0.5s;
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 768px) {
        width: calc(50% - 8px);
        margin-bottom: 20px;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
      .thumbnail_container {
        width: 100%;
        // background: #000;
        // background: #E37428;
        transition: 0.5s;
      }
      .thumbnail {
        width: 100%;
        height: 200px;
        opacity: 0.8;
        object-fit: cover;
        vertical-align: bottom;
      }
      .description_container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        padding: 0 10px;
        border: 1px solid #eee;
        border-top: none;
      }
      .title {
        font-size: 15px;
        margin: 5px 0 5px;
        color: #444242;
        transition: 0.5s;
      }
      .date {
        font-size: 12px;
        text-align: right;
        margin: 0 0 10px;
        color: #444242;
        transition: 0.5s;
      }
      &:hover {
        box-shadow: 0 0px 5px rgba(0,0,0,0.2);
        .thumbnail_container {
          background: #E37428;
        }
        .title {
          color: #E37428;
        }
      }
    }
    .more_blogs_wrapper {
      text-align: center;
      margin: 30px 0 30px;
    }
    .more_blogs {
      border: 1px solid #444242;
      color: #444242;
      font-weight: bold;
      padding: 15px 30px;
      text-decoration: none;
      font-size: 14px;
      transition: 0.5s;
      &:hover {
        border: 1px solid #E37428;
        color: #E37428;
      }
    }
  }

  #time_section {
    margin-top: 40px;
    .time_section_wrapper {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding-bottom: 40px;
      width: 80%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }
    }
    .time_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .left {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
      }
      img {
        width: 80%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .right {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
      figure {
        @media (max-width: 768px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
      table {
        border-collapse: collapse;
        width: 100%;
        tbody td {
          border: solid 1px #444242;
          padding: 10px 20px;
          text-align: center;
          &:first-child {
            font-weight: bold;
          }
        }
      }
      figcaption {
        text-align: right;
        font-size: 12px;
        margin-top: 3px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      a {
        color: #E37428;
      }
    }
    .reserve_btn_container {
      text-align: center;
      margin-top: 30px;
      @media (max-width: 768px) {
        margin-top: 40px;
      }
      .reserve_btn {
        border: 1px solid #E37428;
        display: inline-block;
        padding: 15px 80px;
        color: #E37428;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.5s;
        font-weight: bold;
        text-decoration: none;
        &:hover {
          background: #E37428;
          color: #fff;
        }
      }
    }
  }

  #access_section {
    margin-top: 40px;
    .access_section_wrapper {
      padding-bottom: 40px;
      width: 80%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }
    }
    .access_container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .left {
      width: 100%;
      box-sizing: border-box;
      @media (max-width: 576px) {
        margin-bottom: 20px;
      }
      .map_container {
        width: 100%;
      }
    }
    .right {
      width: 100%;
      .address_container {
        border: 1px solid #bbbbbb;
        .address {
          margin: 20px 20px 0;
          padding-bottom: 20px;
          border-bottom: 1px solid #bbbbbb;
        }
        .train {
          margin: 20px;
        }
      }
    }
  }

  #sns_section {
    margin-top: 40px;
    background-size: 100%;
    padding: 60px 0;
    position: relative;
    background-size: cover;
    background-position: center;
    @media (max-width: 576px) {
      padding: 60px 0;
    }

    &:after {
      content: '';
      background-color: rgba(0,0,0,.5);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .sns_section_wrapper {
      display: flex;
      justify-content: space-between;
      width: 70%;
      margin: 0 auto;
      z-index: 10;
      position: relative;

      @media (max-width: 576px) {
        width: 80%;
        display: block;
      }
    }

    .left {
      .title {
        font-size: 30px;
        margin: 0 0 20px 0;
        color: #fff;
        font-weight: 600;
      }
      .description {
        font-size: 15px;
        margin: 0;
        color: #fff;
        @media (max-width: 576px) {
          margin-bottom: 25px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .instagram_btn {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 15px 55px;
        text-decoration: none;
        color: #000;
        font-weight: 500;
        transition: 0.4s;
        @media (max-width: 576px) {
          justify-content: center;
          width: 100%;
        }

        &:hover {
          opacity: 0.8;
        }

        img {
          width: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}
