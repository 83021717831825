#coming_soon_page {
  .coming_soon_wrapper {
    width: 80%;
    margin: 0 auto;
    h2 {
      text-align: center;
    }
    .coming_soon_img_container {
      width: 50%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
  }
}
