#information_page {
  padding: 50px 0;
  border: 1px solid rgba(0,0,0,0.1);
  .blog_list_wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .blog_lists {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
  }
  .blog_container {
    width: calc(25% - 8px);
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    margin-right: 8px;
    margin-bottom: 10px;
    transition: 0.5s;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 768px) {
      width: calc(50% - 8px);
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    .thumbnail_container {
      width: 100%;
      background: #000;
      transition: 0.5s;
    }
    .thumbnail {
      width: 100%;
      height: 200px;
      opacity: 0.8;
      object-fit: cover;
      vertical-align: bottom;
    }
    .description_container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      padding: 0 10px;
      border: 1px solid #eee;
      border-top: none;
    }
    .title {
      font-size: 15px;
      margin: 5px 0 5px;
      color: #444242;
      transition: 0.5s;
    }
    .date {
      font-size: 12px;
      text-align: right;
      margin: 0 0 10px;
      color: #444242;
      transition: 0.5s;
    }
    &:hover {
      box-shadow: 0 0px 5px rgba(0,0,0,0.2);
      .thumbnail_container {
        background: #E37428;
      }
      .title {
        color: #E37428;
      }
    }
  }
}
