footer {
  .wrapper {
    margin: 0 30px;
    @media (max-width: 576px) {
      margin: 0 10px;
    }
  }

  .logo_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 300px;
    }
  }

  .sns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 40px 30px 0;

    li {
      list-style: none;
      margin-left: 40px;
      font-size: 15px;
      &:first-child {
        margin-left: 0;
      }
    }

    .line,
    .instagram {
      margin-left: 40px;
      a {
        display: flex;
        align-items: center;
      }
      img {
        width: 20px;
      }
    }
  }

  .address {
    text-align: center;
    margin-top: 0;
    margin-bottom: 25px;

    br {
      display: none;
      @media (max-width: 576px) {
        display: block;
      }
    }
  }

  .privacy_policy_link {
    text-align: center;
    font-size: 12px;
    a {
      color: #ccc;
      text-decoration: none;
    }
  }

  .catch_copy {
    text-align: center;
    color: #ccc;
  }
}
